export default class Search {

    private static allMedia() {
        return {
            type: 'all-media',
            page: 'SearchAllMedia'
        }
    }

    private static online() {
        return {
            type: 'products',
            page: 'SearchOnlineTraining',
        }
    }

    private static publishing() {
        return {
            type: 'products',
            page: 'SearchPublishingProducts'
        }
    }

    private static topics() {
        return {
            type: 'topics',
            page: 'SearchTopics'
        }
    }

    private static moreContent() {
        return {
            type: 'more-content',
            page: 'SearchMoreContent'
        }
    }

    private static mapType(type: string) {
       switch (type) {
            case 'all-media':
                return Search.allMedia()
            case 'publishing':
                return Search.publishing()
            case 'online':
                return Search.online()
            case 'topics':
                return Search.topics()
            case 'more-content':
                return Search.moreContent()
            default:
                return Search.allMedia()
        }
    }

    static generateApiEndpoint(type: string | null, term: string | null = null): string {
        if (!type) {
            return window.location.origin;
        }

        let slug = '/search/' + Search.mapType(type).type;

        if (term) {
            slug += '?term=' + term;

            slug += '&autocomplete=true'

            if (type === 'online' || type === 'publishing' || type === 'all-media') {
                slug += '&productType=' + type
            }
        }

        return window.location.origin + slug;
    }

    static generateLink(type: string | null, term: string | null = null): string {
        if (!type) {
            return window.location.origin;
        }

        let slug = '/Suche/' + Search.mapType(type).page;

        if (term) {
            slug += '?term=' + term;
        }

        return window.location.origin + slug;
    }
}